import type { ProtectionStatusSchedule, UpdatedProtectionStatus } from '@orus.eu/protection'
import { translate, type Language } from '@orus.eu/translations'
import type { ContractDynamicDocumentType } from './download-filenames'
import { formatTimestamp } from './format'
import type { IconLabelColor } from './icon-label'

const commentsPerUpdateStatus: {
  [Status in UpdatedProtectionStatus]: (timestamp: number) => string
} = {
  suspended: (timestamp) => `Suspendu le ${formatTimestamp(timestamp)}`,
  terminated: (timestamp) => `Résilié le ${formatTimestamp(timestamp)}`,
  active: (timestamp) => `Réactivé le ${formatTimestamp(timestamp)}`,
}

function getStatusComment(scheduledUpdates: ProtectionStatusSchedule['scheduledStatusUpdates'] | null): string | null {
  if (scheduledUpdates === null) {
    return null
  }

  if (scheduledUpdates.suspended.scheduledAtTimestamp !== null) {
    return commentsPerUpdateStatus.suspended(scheduledUpdates.suspended.scheduledAtTimestamp)
  }

  if (scheduledUpdates.terminated.scheduledAtTimestamp !== null) {
    return commentsPerUpdateStatus.terminated(scheduledUpdates.terminated.scheduledAtTimestamp)
  }

  if (scheduledUpdates.active.scheduledAtTimestamp !== null) {
    return commentsPerUpdateStatus.active(scheduledUpdates.active.scheduledAtTimestamp)
  }

  return null
}

type ContractStatusUiProps = IconLabelColor & {
  comment?: string
  timestamp?: number
}

export function getContractStatusUiProps(protectionStatusSchedule: ProtectionStatusSchedule): ContractStatusUiProps {
  switch (protectionStatusSchedule.currentStatus) {
    case 'active': {
      return {
        icon: 'circle-check-regular',
        label: 'Actif',
        variant: 'success',
        comment: getStatusComment(protectionStatusSchedule.scheduledStatusUpdates) ?? undefined,
      }
    }
    case 'not-started':
      return {
        icon: 'hourglass-regular',
        variant: 'informal',
        label: 'Prochainement actif',
      }
    case 'suspended':
      return {
        icon: 'lock-regular',
        variant: 'warning',
        label: 'Suspendu',
        comment: getStatusComment(protectionStatusSchedule.scheduledStatusUpdates) ?? undefined,
      }
    case 'terminated':
      return {
        icon: 'ban-regular',
        variant: 'danger',
        label: 'Résilié',
        comment: getStatusComment(protectionStatusSchedule.scheduledStatusUpdates) ?? undefined,
        timestamp: protectionStatusSchedule.currentStatusCause?.fromTimestamp,
      }
  }
}

export type ContractDocumentType = 'agreed-terms' | 'agreed-terms-draft' | 'payment-schedule'

export const dynamicFileLabels: { [key in ContractDocumentType]: (language: Language) => string } = {
  'agreed-terms': (language) => `Orus - ${translate('filename_agreed_terms', language)}.pdf`,
  'agreed-terms-draft': (language) => `Orus - ${translate('filename_agreed_terms_draft', language)}.pdf`,
  'payment-schedule': (language) => `Orus - ${translate('filename_payments_schedule', language)}.pdf`,
}

export type ContractDocumentDescription =
  | { method: 'get'; fileName: string; url: string; fileType: ContractDynamicDocumentType }
  | { method: 'post'; fileName: string; onClick: () => Promise<void> }
